import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

import { CountUp } from "countup.js"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"

import World from "../../images/icons/world.svg";
import Team from "../../images/icons/team.svg";
import CutPath from "../../images/icons/cut-path.svg";

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default
    const counterOptions = {
      duration: 2,
      separator: ' ',
      suffix: '+'
    };

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-4', {
      delay: 300,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-5', {
      delay: 400,
      viewOffset: {
        right: '-500'
      }
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });

    ScrollReveal().reveal('.sr-c-1', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-years', 11, counterOptions);
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-2', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-brands', 1000, counterOptions);
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-3', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-employee', 100, counterOptions);
        counterMails.start();
      }
    });

    ScrollReveal().reveal('.sr-c-4', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-satisfaction', 96, {
          duration: 2,
          suffix: '%'
        });
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-5', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-delivered', 96.5, {
          duration: 2,
          decimalPlaces: 1,
          decimal: ',',
          suffix: '%'
        });
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-6', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-mails', 400000000, counterOptions);
        counterMails.start();
      }
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Notre équipe - ExpertSender" description="Notre équipe - développez votre activité à l’aide de l’automatisation du marketing multicanal orienté données pour dépasser la concurrence !" />
        <Hero title="Notre équipe" subtitle="Pourquoi sommes-nous un partenaire de confiance pour votre activité ?" />

        <div className="container container--pad pad-t-2 pad-b-1 mar-t-6">
          <div className="section-h sr-hidden sr-1">
            <h2 className="section-h__2 mar-b-6">
              <FormattedMessage id="team.h_1" />
            </h2>
          </div>

          <div className="columns is-variable is-8">
            <div className="column sr-hidden sr-1">
              <World className="section-i__ico" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                Nous disposons de <strong>plus de 100 employés</strong> dans <strong>4 bureaux</strong> sur <strong>3 continents</strong>.
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <Team className="section-i__ico" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                Notre équipe rassemble des <strong>développeurs</strong> expérimentés, des spécialistes du <strong>marketing</strong> et des <strong>ventes</strong>, des <strong>analystes</strong> et <strong>gestionnaires de projets</strong> volontaires et des <strong>gestionnaires de compte dédiés</strong>.
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <CutPath className="section-i__ico section-i__ico--80" />
              <p className="section-h__3 mar-t-2 mar-b-1">
                Nous comptons <strong>plus de 11 ans</strong> d’expérience dans la conception de campagnes marketing et de parcours client efficaces.
              </p>
            </div>
          </div>
        </div>

       {/* <div className="container container--pad mar-b-6">
          <div className="section-h mar-t-6 sr-hidden sr-1">
            <h2 className="section-h__2 mar-b-2">
              <FormattedMessage id="team.h_2" />
            </h2>
            <p className="section-h__3">
              <FormattedMessage id="team.t_4" />
            </p>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-6">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.krzysztofJarecki.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Krzysztof Jarecki
                <span>CEO</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.annaFlis.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Anna Flis
                <span>COO</span>
              </div>
            </div>

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Customer Success & Account Management</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.dougPatfield.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Douglas Patfield
                <span>Global Client Service Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.lukaszDeput.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Łukasz Deput
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.martynaRemiszewska.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Martyna Remiszewska
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.nataliaPaluszkiewicz.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Natalia Paluszkiewicz
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-5">
              <Img fluid={this.props.data.joannaMasorz.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Joanna Masorz
                <span>Account Manager</span>
              </div>
            </div>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.katarzynaPiegat.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Katarzyna Piegat
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.ewaWojcicka.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Ewa Wójcicka
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Kamil Olszewski
                <span>Account Manager</span>
              </div>
            </div>
      
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Emil Zarzeczny
                <span>Account Manager</span>
              </div>
            </div>

            <div className="column is-hidden-mobile" />
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Deliverability & Compliance / Data Protection</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.michalKidon.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Michał Kidoń
                <span>Chief Security Officer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.kacperKruczynski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Kacper Kruczyński
                <span>Deliverability<br />& Compliance Specialist</span>
              </div>
            </div>

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Business Development / Sales</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.piotrCzynsz.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Piotr Czynsz
                <span>Head of Sales<br />and Business Development</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Malwina Pobłocka
                <span>New Business Specialist</span>
              </div>
            </div>

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />

            <div className="column is-hidden-mobile" />
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Marketing</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.alicjaSkopinska.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Alicja Skopińska
                <span>Marketing Director</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.jaroslawWasielewski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Jarek Wasielewski
                <span>Digital Marketing Specialist</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.jacekSuski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Jacek Suski
                <span>Designer & Web Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Klaudiusz Kochanowski
                <span>CRM Manager<br />& Team Operations Coordinator</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Anna Bocian
                <span>Intern</span>
              </div>
            </div>
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Product Management</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.agnieszkaUdymowskaGrecka.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Agnieszka<br />Udymowska-Grecka
                <span>Product Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.jakubKonieczny.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Jakub Konieczny
                <span>Product Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.monikaNiesiolowska.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Monika Niesiołowska
                <span>Business Analyst</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.aleksandraKowalczyk.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Aleksandra Kowalczyk
                <span>UX Designer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-5">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Jakub Dzięgiel
                <span>UX/UI Designer</span>
              </div>
            </div>
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">IT Team</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.marcinBudzinski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Marcin Budziński
                <span>Development Team Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.bartlomiejSwiatek.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Bartłomiej Świątek
                <span>IT Administration Leader</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.tomaszJaskolski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Tomasz Jaskólski
                <span>IT Administrator</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.adrianSlawinski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Adrian Sławiński
                <span>System Administrator</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-5">
              <Img fluid={this.props.data.mateuszNostitzJackowski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Mateusz Nostitz-Jackowski
                <span>Application Developer</span>
              </div>
            </div>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.kazimierzSzadkowski.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Kazimierz Szadkowski
                <span>Application Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.marcinSynak.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Marcin Synak
                <span>Application Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.annaZabrocka.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Anna Zabrocka
                <span>Application Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.michalDzienisz.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Michał Dzienisz
                <span>Application Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-5">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Krzysztof Olszer
                <span>Application Developer</span>
              </div>
            </div>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Marcin Łukaszuk
                <span>Application Developer</span>
              </div>
            </div>
      
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.placeholder.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Artur Gruchała
                <span>Application Developer</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.tomaszGraul.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Tomasz Graul
                <span>QA Specialist</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.malwinaTuzimek.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Malwina Tuzimek
                <span>QA Specialist</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.klaudiaWiecko.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Klaudia Więcko
                <span>QA Specialist</span>
              </div>
            </div>
          </div>

          <div className="section-h mar-t-3 mar-b-3 sr-hidden sr-1">
            <h3 className="section-h__3 has-text-weight-bold">Administration & Finance</h3>
          </div>

          <div className="section-t columns is-mobile is-flow-mobile mar-t-3">
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.jakubSzudejko.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Jakub Szudejko
                <span>Finance Director</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-2">
              <Img fluid={this.props.data.angelinaJancen.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Angelina Jancen
                <span>Office Manager</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-3">
              <Img fluid={this.props.data.joannaHewitt.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Joanna Hewitt
                <span>Chief Accountant</span>
              </div>
            </div>

            <div className="column is-two-fifths-mobile sr-hidden sr-4">
              <Img fluid={this.props.data.joannaGladkowska.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Joanna Gładkowska
                <span>Administration Specialist</span>
              </div>
            </div>
 
            <div className="column is-two-fifths-mobile sr-hidden sr-1">
              <Img fluid={this.props.data.oliwiaRutkowska.childImageSharp.fluid} className="section-t__photo" />
              <div className="section-t__name mar-t-2">
                Oliwia Rutkowska
                <span>Administrative Assistant</span>
              </div>
            </div>
          </div>
    </div> */}

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default SuccessStories

export const pageQuery = graphql`
  query {
    krzysztofJarecki: file(relativePath: { eq: "team/circle/krzysztof-jarecki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    annaFlis: file(relativePath: { eq: "team/circle/anna-flis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    lukaszKalita: file(relativePath: { eq: "team/circle/lukasz-kalita.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    joannaMasorz: file(relativePath: { eq: "team/circle/joanna-masorz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    katarzynaPiegat: file(relativePath: { eq: "team/circle/katarzyna-piegat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    michalKidon: file(relativePath: { eq: "team/circle/michal-kidon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    
    piotrCzynsz: file(relativePath: { eq: "team/circle/piotr-czynsz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    dougPatfield: file(relativePath: { eq: "team/circle/doug-patfield.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jakubKonieczny: file(relativePath: { eq: "team/circle/jakub-konieczny.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    lukaszDeput: file(relativePath: { eq: "team/circle/lukasz-deput.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    martynaRemiszewska: file(relativePath: { eq: "team/circle/martyna-remiszewska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    nataliaPaluszkiewicz: file(relativePath: { eq: "team/circle/natalia-paluszkiewicz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    joannaMasorz: file(relativePath: { eq: "team/circle/joanna-masorz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    katarzynaPiegat: file(relativePath: { eq: "team/circle/katarzyna-piegat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    ewaWojcicka: file(relativePath: { eq: "team/circle/ewa-wojcicka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    kacperKruczynski: file(relativePath: { eq: "team/circle/kacper-kruczynski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    alicjaSkopinska: file(relativePath: { eq: "team/circle/alicja-skopinska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jaroslawWasielewski: file(relativePath: { eq: "team/circle/jaroslaw-wasielewski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jacekSuski: file(relativePath: { eq: "team/circle/jacek-suski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    agnieszkaUdymowskaGrecka: file(relativePath: { eq: "team/circle/agnieszka-udymowska-grecka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    marcinBudzinski: file(relativePath: { eq: "team/circle/marcin-budzinski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    bartlomiejSwiatek: file(relativePath: { eq: "team/circle/bartlomiej-swiatek.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tomaszJaskolski: file(relativePath: { eq: "team/circle/tomasz-jaskolski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    mateuszNostitzJackowski: file(relativePath: { eq: "team/circle/mateusz-nostitz-jackowski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    kazimierzSzadkowski: file(relativePath: { eq: "team/circle/kazimierz-szadkowski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    marcinSynak: file(relativePath: { eq: "team/circle/marcin-synak.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    annaZabrocka: file(relativePath: { eq: "team/circle/anna-zabrocka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    michalDzienisz: file(relativePath: { eq: "team/circle/michal-dzienisz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tomaszGraul: file(relativePath: { eq: "team/circle/tomasz-graul.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    malwinaTuzimek: file(relativePath: { eq: "team/circle/malwina-tuzimek.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    klaudiaWiecko: file(relativePath: { eq: "team/circle/klaudia-wiecko.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jakubSzudejko: file(relativePath: { eq: "team/circle/jakub-szudejko.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    angelinaJancen: file(relativePath: { eq: "team/circle/angelina-jancen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    joannaHewitt: file(relativePath: { eq: "team/circle/joanna-hewitt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    joannaGladkowska: file(relativePath: { eq: "team/circle/joanna-gladkowska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    annaAntoniakBuca: file(relativePath: { eq: "team/circle/anna-antoniak-buca.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    monikaNiesiolowska: file(relativePath: { eq: "team/circle/monika-niesiolowska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    oliwiaRutkowska: file(relativePath: { eq: "team/circle/oliwia-rutkowska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    adrianSlawinski: file(relativePath: { eq: "team/circle/adrian-slawinski.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    aleksandraKowalczyk: file(relativePath: { eq: "team/circle/aleksandra-kowalczyk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    placeholder: file(relativePath: { eq: "team/circle/placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
